<template>
 <div :class="windowClass">
   <v-container>
     <v-toolbar flat>
       <div class="text-h4">Fight Summary 3</div>
       <v-spacer/>
     
     </v-toolbar>
     <v-divider></v-divider>
    <v-row dense class="mt-10">
       <v-col cols="12">
         <v-col cols="12">
     <v-toolbar flat dense>
       <v-spacer/>
        <v-spacer/>
       <v-autocomplete 
        label="Select Event"
        v-model="event"
        :items="events"
        dense
        outlined
        item-text="name"
        return-object
        @change="getFights()"
       />
       
     </v-toolbar>
      </v-col>
      <v-toolbar class="mb-2" dense>EVENT NAME: {{event.name}}<v-spacer></v-spacer>DATE: {{event.event_date}}<v-spacer></v-spacer>
        <!-- <span style="width:200px;">
         
          <v-select
          dense
          hide-details
            v-model="search"
            :items="['ALL', 'Exclude darw and cancelled']"
          />
    </span> -->
    </v-toolbar>
    <v-row no-gutters>
        <v-col cols="12">
          <v-card>
            <v-toolbar dense>
                EVENT SUMMARY
            </v-toolbar>
                <table class="va-table md-font">
                    <tr>
                      <td style="background: #6D9046; color: #fff" class="pa-1 text-center">TOTAL MERON GROSS</td>
                      <td style="background: #6D9046; color: #fff" class="pa-1 text-center">TOTAL WALA GROSS</td>
                      <td style="background: #6D9046; color: #fff" class="pa-1 text-center"> GROSS</td>
                      <td style="background: #6D9046; color: #fff" class="pa-1 text-center">COMMISSION</td>
                    </tr>
                    <tr>
                      <td  class="pa-1 text-center text-h4">{{$money(amountdisplay(TOTALMERON))}}</td>
                      <td  class="pa-1 text-center text-h4">{{$money(amountdisplay(TOTALWALA))}}</td>
                      <td class="pa-1 text-center text-h4">{{$money(amountdisplay(TOTALMERON + TOTALWALA))}}</td>
                      <td  class="pa-1 text-center text-h4">{{$money(amountdisplay(commission(TOTALWALA +TOTALMERON)))}}</td>
                    </tr>
                </table>

                <table class="va-table md-font mt-4">
                    <tr>
                      <td style="background: #3C3C3C; color: #fff" class="pa-1 text-center">CANCELLED</td>
                      <td style="background: #3C3C3C; color: #fff" class="pa-1 text-center">DRAW</td>
                    </tr>
                    <tr>
                      <td  class="pa-1 text-center text-h4">{{$money(amountdisplay(CANCELTOTAL))}}</td>
                      <td  class="pa-1 text-center text-h4">{{$money(amountdisplay(DRAWTOTAL))}}</td>
                    </tr>
                </table>

          </v-card>
        </v-col>
    </v-row>

      <v-row no-gutters>
          <v-col cols="12" class="mt-12">

                FIGHT DETAILS
          </v-col>
      </v-row>

       <table class="va-table md-font">
                <tr>
                  <td style="background: #313131; color: #fff" class="pa-1">FIGHT #</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">MERON GROSS</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">WALA GROSS</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">TOTAL GROSS</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">COMMISSION</td>
                  <!-- <td style="background: #313131; color: #fff" class="pa-1 text-right">PROFIT </td> -->
                </tr>
                <tr v-for="(item,index) in fights" :key="index">
                    <td class="border_bot">
                       <strong :class="STATUS_COLOR(item.result)">{{ item.fight_no }} - {{ item.result }}</strong>
                    </td>
                
                  
                    <td class="border_bot text-right">{{$money(amountdisplay(item.meron_collection))}}</td>
                    <td  class="border_bot text-right">{{$money(amountdisplay(item.wala_collection))}}</td>
                     <td  class="border_bot text-right">{{$money(amountdisplay(item.meron_collection + item.wala_collection))}}</td>
                      <td  class="border_bot text-right">{{$money(amountdisplay(commission(item.wala_collection + item.meron_collection)))}}</td>
                      <!-- <td  class="border_bot text-right">{{$money(profit(item.wala_collection + item.meron_collection))}}</td> -->
                      
                </tr>
                <tr>
                  <th></th>
                  <th class="text-right">{{$money(amountdisplay(ALLTOTALMERON))}}</th>
                  <th class="text-right">{{$money(amountdisplay(ALLTOTALWALA))}}</th>
                  <th class="text-right">{{$money(amountdisplay(ALLTOTALWALA + ALLTOTALMERON))}}</th>
                  <th class="text-right">{{$money(amountdisplay(commission(ALLTOTALWALA + ALLTOTALMERON)))}}</th>
                  <!-- <th class="text-right">{{$money(profit(TOTALWALA +TOTALMERON))}} </th> -->
                </tr>
           </table>
       </v-col>
    </v-row>
    </v-container>
  
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        formform: false,
        state: 'new',
        fight:{},
        event_id:0,
        fights: [],
        events: [],
        event: {},
        search:'ALL',
        headers: [
            { text: 'Fight No.', value:"event_date"},
            { text: 'Meron', value:"meron", align:"left"},
            { text: 'Wala', value:"wala", align:"left"},
             { text: 'Meron Gross', value:"meron", align:"left"},
            { text: 'Wala Gross', value:"wala", align:"left"},
            { text: 'Status', value:"status"},
            { text: 'Result', value:"result", align: 'right'},
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
       // this.getItems()
        this.getEvents()
       // this.GET_BREEDERS()
        //this.GET_OWNERS()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      FIGHTFIGTHS() {
          if(this.search == 'ALL') {
              return this.fights
          } else {
            return this.COUNTEDFIGHTS
          }
      },
      COUNTEDFIGHTS(){
      // let counted = []
        var fights = this.fights.reduce((res,item)=>{
          if(item.result == "Meron" ||item.result == "Wala" ) {
            res.push(item)
          }
        return res
        },[])
        return fights
      },

      NOTCOUNTEDFIGHTS(){
      // let counted = []
        var fights = this.fights.reduce((res,item)=>{
          if(item.result == "Cancelled" ||item.result == "Draw" ) {
            res.push(item)
          }
        return res
        },[])
        return fights
      },
      CANCELLEDFIGHTS(){
      // let counted = []
        var fights = this.fights.reduce((res,item)=>{
          if(item.result == "Cancelled") {
            res.push(item)
          }
        return res
        },[])
        return fights
      },

      DRAWFIGHTS(){
      // let counted = []
        var fights = this.fights.reduce((res,item)=>{
          if(item.result == "Draw") {
            res.push(item)
          }
        return res
        },[])
        return fights
      },


      ALLTOTALMERON(){
        var total = this.fights.reduce((res,item)=>res+=item.meron_collection,0)
        return total
      },
      ALLTOTALWALA(){
        var total = this.fights.reduce((res,item)=>res+=item.wala_collection,0)
        return total
      },

      CANCELTOTAL(){
        var total = this.CANCELLEDFIGHTS.reduce((res,item)=>res+=(item.meron_collection+item.wala_collection),0)
        return total
      },
      DRAWTOTAL(){
        var total = this.DRAWFIGHTS.reduce((res,item)=>res+=(item.meron_collection+item.wala_collection),0)
        return total
      },
      TOTALMERON(){
        var total = this.COUNTEDFIGHTS.reduce((res,item)=>res+=item.meron_collection,0)
        return total
      },
      TOTALWALA(){
        var total = this.COUNTEDFIGHTS.reduce((res,item)=>res+=item.wala_collection,0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      commission(amount){
        let percentage = this.$IsNum(this.event.percentage)/100
        let op_percentage = this.$IsNum(this.$session.get('commission'))/100
        percentage = percentage -  op_percentage
        return amount * percentage
      },
      amountdisplay(amount){
       // let percentage = this.$IsNum(this.$session.get('commission'))/100
        //let less = this.$IsNum(amount) * percentage
        return  this.$IsNum(amount) 
      },
      // amountdisplay(amount){
      //  // let percentage = this.$IsNum(this.$session.get('commission'))/100
      //   //let less = this.$IsNum(amount) * percentage
      //   return  this.$IsNum(amount) 
      // },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.fight = data.fight
              this.Save()
          } else {
             this.getItems()
           
          }
      },
      formResEvent(){
        this.formform = false
        setTimeout(()=>{
                 this.getItems()
            }, 3000)
      },
       getFights(){
          this.$http.post("event/fights", {id: parseInt(this.event.id)}).then(response => {
            response.data.items !=null? this.fights = response.data.items:this.fights=[] 
           
        }).catch(e => {
          console.log(e)
        })
      },
       getEvents(){
           this.$http.get("event/list").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
      },
      
    }
  }
</script>